import { Component } from '@angular/core';

@Component({
  selector: 'jds-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  title = 'jds';
}
